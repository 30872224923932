import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { NumberInput } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/number-input",
  "title": "NumberInput",
  "navTitle": "NumberInput"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <NumberInput id="example" helperText="Assistive text" label="Total compensation" unit="€" defaultValue={1000} style={{
        maxWidth: '320px'
      }} mdxType="NumberInput" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with a number input.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Make sure that the label is clear and concise. The user should immediately understand what number they are supposed to input.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`HDS Number input also supports displaying an unit for the number. Displaying the unit is not mandatory. Only use it when you think it will help the user.`}
        <ul parentName="li">
          <li parentName="ul">{`Display the unit for `}<ExternalLink href="https://en.wikipedia.org/wiki/International_System_of_Units" mdxType="ExternalLink">{`SI units`}</ExternalLink>{` (such as meters) and currencies.`}</li>
          <li parentName="ul">{`The unit does not need to be displayed when it is self-evident for the user, such as in "Number of people"`}</li>
        </ul>
      </li>
      <li parentName="ul">{`It is recommended to give the number input a default value. Placeholders should be avoided in number inputs.`}</li>
      <li parentName="ul">{`HDS Number input supports visual steppers that can be set to increase and decrease the number value by a set amount.`}
        <ul parentName="li">
          <li parentName="ul">{`Steppers can be used when changes to the value are small or when they are intuitive to the user (e.g. from 0 to 100 with steps of 10).`}</li>
          <li parentName="ul">{`You should not use steppers when large value changes are expected.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Default HDS Number input comes without stepper. The number is always inputted manually. Using this over a text input is still helpful since it has a `}<inlineCode parentName="p">{`type=number`}</inlineCode>{` to aid screen readers and mobile keyboards.`}</p>
    <p>{`When applicable, a unit can be set for the input by using the `}<inlineCode parentName="p">{`unit`}</inlineCode>{` prop.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <NumberInput id="default" helperText="Assistive text" label="Total compensation" unit="€" defaultValue={1000} style={{
        maxWidth: '320px'
      }} mdxType="NumberInput" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-steppers",
      "style": {
        "position": "relative"
      }
    }}>{`With steppers`}<a parentName="h4" {...{
        "href": "#with-steppers",
        "aria-label": "with steppers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Steppers can be enabled via providing a `}<inlineCode parentName="p">{`step`}</inlineCode>{` property. Use this variant when value changes are small and you can determine logical amounts for an increase and a decrease step.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <NumberInput id="stepper" helperText="Assistive text" label="Number of attendees" minusStepButtonAriaLabel="Decrease by one" plusStepButtonAriaLabel="Increase by one" step={1} defaultValue={5} style={{
        maxWidth: '320px'
      }} mdxType="NumberInput" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-min-and-max-values",
      "style": {
        "position": "relative"
      }
    }}>{`With min and max values`}<a parentName="h4" {...{
        "href": "#with-min-and-max-values",
        "aria-label": "with min and max values permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Steppers can be enabled by providing a `}<inlineCode parentName="p">{`step`}</inlineCode>{` property. Use this variant when value changes are small and you can determine logical amounts for an increase and a decrease step.`}</p>
    <p>{`If your input has special requirements, it is a good practice to describe them in the assistive text.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <NumberInput id="stepper-minmax" helperText="At least 3 attendees are required" label="Number of attendees" min={3} max={99} minusStepButtonAriaLabel="Decrease by one" plusStepButtonAriaLabel="Increase by one" step={1} defaultValue={3} style={{
        maxWidth: '320px'
      }} mdxType="NumberInput" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      